<template>
  <el-dialog
    center
    modal-centered
    title="Выбор курса"
    custom-class="up-modal-choose"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-input v-model="searchValue" placeholder="Введите название" clearable />
    <el-button
      v-if="hasChooseAllBtn"
      class="up-modal-choose__choose-all-btn"
      type="text"
      @click="$emit('choose-course', 'all')"
    >
      Выбрать все курсы
    </el-button>
    <template v-for="direction in filteredCoursesTree">
      <AppCollapse :key="direction.id" :ref="direction.id">
        <template slot="title">{{ direction.name }}</template>
        <AppCollapse
          v-for="category in direction.childs"
          :key="`category-${category.id}`"
          :ref="category.id"
        >
          <template slot="title"> {{ category.name }}</template>
          <el-button
            v-for="course in category.courses"
            :key="course.id"
            class="up-modal-choose__button"
            type="text"
            @click="$emit('choose-course', course.id)"
          >
            {{ course.name }}
          </el-button>
        </AppCollapse>
      </AppCollapse>
    </template>
  </el-dialog>
</template>

<script>
import AppCollapse from "@/components/AppCollapse";

import filteredCousesMixin from "@/mixins/filteredCouses.mixin";

export default {
  name: "AppModalChooseCourse",
  mixins: [filteredCousesMixin],
  components: { AppCollapse },
  props: {
    coursesTree: Array,
    hasChooseAllBtn: Boolean,
  },
  methods: {
    suitableCategories(categories) {
      return categories.reduce((acc, category) => {
        const cName = category.name.toLowerCase();
        const svalue = this.searchValue.toLowerCase();

        const isCaregorySuitable = cName.includes(svalue);

        if (!category.courses || !category.courses.length) {
          return isCaregorySuitable ? [...acc, category] : acc;
        }

        const suitableCourses = this.suitableCourses(category.courses);

        // Если курсы не подходят под фильтр, но категория подходит
        if (suitableCourses.length === 0) {
          return isCaregorySuitable ? [...acc, category] : acc;
        }

        const newCategory = { ...category };

        newCategory.courses = suitableCourses;

        this.openCollapse(category.id);

        return [...acc, newCategory];
      }, []);
    },
    suitableCourses(courses) {
      return courses.reduce((acc, course) => {
        const cName = course.name.toLowerCase();
        const svalue = this.searchValue.toLowerCase();

        return cName.includes(svalue) ? [...acc, course] : acc;
      }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/modal-choose.scss";
</style>