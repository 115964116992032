<template>
  <el-form class="up-request-form" :model="form" :rules="rules" ref="form">
    <el-form-item label="Клиент:" prop="customerId">
      <el-select
        v-model="form.customerId"
        v-loading="!customersLoaded"
        filterable
      >
        <el-option
          v-for="c in customers"
          :label="c.name"
          :value="c.id"
          :key="c.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Курс:" prop="courseId">
      <el-select
        v-model="form.courseId"
        v-loading="!coursesLoaded"
        popper-class="up-choose-course-popover"
        @visible-change="openModalChooseCourse"
      >
        <template v-for="direction in coursesTree">
          <template v-for="category in direction.childs">
            <el-option
              v-for="course in category.courses"
              :key="course.id"
              :label="course.name"
              :value="course.id"
            />
          </template>
        </template>
      </el-select>
    </el-form-item>
    <el-form-item label="Доступ:">
      <el-date-picker
        v-model="form.dateUntil"
        type="date"
        placeholder="Выберите дату"
        title="Дата окончания - через 3 месяца от выбранной даты"
        format="dd MMMM yyyy"
        value-format="timestamp"
        :picker-options="{
          disabledDate,
        }"
      />
    </el-form-item>
    <el-form-item v-if="isEdit" label="Статус:">
      <el-select v-model="form.orderStatus">
        <el-option label="Активна" value="active" />
        <el-option label="Не активна" value="inactive" />
        <el-option label="Не обработана" value="requested" />
<!--        <el-option label="Завершена" value="closed" />-->
      </el-select>
    </el-form-item>
    <AppModalChooseCourse
      :visible.sync="modalChooseCoursevisible"
      :courses-tree="coursesTree"
      @choose-course="chooseCourseHandler"
    />
  </el-form>
</template>

<script>
import validatableFormMixin from "@/mixins/validatableForm.mixin";
import chooseCourseMixin from "@/mixins/chooseCourse.mixin";

const requiredValidation = [
  { required: true, message: "Необходимо выбрать", trigger: "blur" },
];

export default {
  name: "RequestForm",
  mixins: [validatableFormMixin, chooseCourseMixin],
  props: {
    value: null,
    isEdit: Boolean,
  },
  data: () => ({
    customers: [],
    customersLoaded: false,
    rules: {
      customerId: requiredValidation,
      courseId: requiredValidation,
    },
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    disabledDate(date) {
      return date.getTime() < Date.now();
    },
    async fetchCustomers() {
      try {
        const { data: customers } = await this.$axios.get("/manager/customers");

        this.customers = customers;
        this.customersLoaded = true;
      } catch (e) {
        //
      }
    },
    chooseCourseHandler(courseId) {
      this.form.courseId = courseId;
      this.modalChooseCoursevisible = false;
    },
  },
  created() {
    this.fetchCustomers();
    this.fetchTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/manager/requests/request-form.scss";
</style>

<style lang="scss">
.up-choose-course-popover {
  display: none !important;
}
</style>