import AppModalChooseCourse from "@/components/AppModalChooseCourse";

export default {
  components: { AppModalChooseCourse },
  data: () => ({
    coursesTree: [],
    coursesLoaded: false,
    modalChooseCoursevisible: false,
  }),
  methods: {
    openModalChooseCourse(visible) {
      if (!visible) return;
      this.modalChooseCoursevisible = true;
    },
    async fetchTree() {
      this.$nprogress.start();

      try {
        const { data: coursesTree } = await this.$axios.get(
          "/constructor/treeFull"
        );

        this.coursesTree = coursesTree;
        this.coursesLoaded = true;
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.$nprogress.done();
      }
    },
  },
}