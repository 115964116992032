<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    v-bind="$attrs"
    v-on="$listeners"
    title="Добавление слушателей"
    custom-class="up-listeners-modal"
    @open="clearData"
  >
    <el-form>
      <el-form-item label="Поле для добавления фио">
        <el-input v-model="form.name" placeholder="Введите фио" />
      </el-form-item>
      <el-form-item label="Поле для добавления email">
        <el-input v-model="form.email" placeholder="Введите email" />
      </el-form-item>
      <el-form-item label="Поле для добавления телефона">
        <el-input v-model="phoneNumber" placeholder="Введите телефон" />
      </el-form-item>
      <el-form-item label="После добавления одного слушателя, нажмите Enter">
        <VueEditor
          v-model="content"
          placeholder="Здесь можно добавить множество слушателей."
          id="editor"
        />
      </el-form-item>
    </el-form>
    <div
      v-if="listeners.length > 0"
      class="up-listeners-modal__items-example up-examples"
    >
      <h2 class="up-examples__title">Будут добавлены следующие слушатели</h2>
      <div v-for="(l, i) in listeners" :key="i" class="up-examples__item">
        <p
          class="up-examples__item-paragraph"
          :class="{ 'is-error': l.isNotCorrectString }"
        >
          <span>ФИО:</span>{{ l.name }}
        </p>
        <p class="up-examples__item-paragraph">
          <span>Email:</span>{{ l.email }}
        </p>
        <p class="up-examples__item-paragraph">
          <span>Телефон:</span>{{ l.phone }}
        </p>
      </div>
    </div>
    <footer class="up-listeners-modal__footer">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button
        type="success"
        @click="saveHandler"
        :disabled="listeners.length === 0"
      >
        Добавить
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
import Str from "@/helpers/Str";

import { VueEditor } from "vue2-editor";

export default {
  name: "AddListenersModal",
  components: { VueEditor },
  props: {
    addedListeners: Array,
  },
  data: () => ({
    form: {
      name: "",
      email: "",
      phone: "",
    },
    content: "",
  }),
  computed: {
    phoneNumber: {
      get() {
        return this.form.phone;
      },
      set(val) {
        this.form.phone = Str.phoneFormatted(val);
      },
    },
    listeners() {
      const listenersArr = this.content.split(/<p>|<\/p>/g);

      const formattedArr = listenersArr.reduce((acc, curr) => {
        if (!curr || curr.length < 5) return acc;

        const data = curr
          .split(" ")
          .filter((d) => d)
          .join(" ");

        return [...acc, this.getListenerDataFormatted(data)];
      }, []);

      if (this.form.name) {
        formattedArr.push({
          name: this.form.name,
          email: this.form.email,
          phone: Str.phoneFormatted(this.form.phone),
        });
      }

      return formattedArr;
    },
  },
  methods: {
    onPasteInEditor() {
      const editor = document.querySelector("#editor");

      editor.addEventListener("paste", (e) => {
        e.stopPropagation();
        e.preventDefault();

        let text = e.clipboardData.getData("text/plain");

        if (document.queryCommandSupported("insertText")) {
          document.execCommand("insertText", false, text);
        } else {
          document.execCommand("paste", false, text);
        }
      });
    },
    isPhonesEqual(phone1, phone2) {
      return Str.digitsFormatted(phone1) === Str.digitsFormatted(phone2);
    },
    getListenerDataFormatted(data) {
      let regex =
        /^(.*?)(\s(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))){0,1}\s((\+)?([- _():=+]?\d[- _():=+]?){10,14})(\s*?)$/;

      const regexResult = data.replace(/&nbsp;/g, ' ').match(regex);

      if (!regexResult) {
        return {
          name: data.replace(/&nbsp;/g, ' '),
          isNotCorrectString: true,
        };
      }

      return {
        name: regexResult[1],
        email: regexResult[2],
        phone: Str.phoneFormatted(regexResult[11]),
      };
    },
    hasIncorrectRecords() {
      if (this.listeners.find((l) => l.isNotCorrectString)) {
        this.$message({
          message:
            "В добавляемых записях о слушателях обнаружены ошибки. Внести исправления и повторите попытку",
          type: "error",
          duration: 10000,
        });

        return true;
      }

      return false;
    },
    hasExistsListeners() {
      const existsListeners = this.listeners.reduce((acc, curr) => {
        const isExist = this.addedListeners.find((l) =>
          this.isPhonesEqual(l.phone, curr.phone)
        );

        return isExist ? [...acc, curr.phone] : acc;
      }, []);

      if (existsListeners.length) {
        this.$message({
          message: `Слушатели с номерами телефонов: ${existsListeners.join(
            ", "
          )} уже присутствуют в заявке. Удалите дубли и повторите попытку`,
          type: "error",
          duration: 15000,
        });

        return true;
      }

      return false;
    },
    hasEmptyRecords() {
      const isNotCorrectString = this.listeners.find(
        (l) => Str.empty(l.name) || Str.empty(l.phone)
      );

      if (isNotCorrectString) {
        this.$message({
          message:
            "Необходимо корректно указать Имя слушателя и номер телефона",
          type: "error",
          duration: 10000,
        });

        return true;
      }

      return false;
    },
    hasDuplicatePhones() {
      let phoneDuplicates = [];

      this.listeners.forEach((l) => {
        const phonesCount = this.listeners.reduce((acc, curr) => {
          if (this.isPhonesEqual(curr.phone, l.phone)) acc++;

          return acc;
        }, 0);

        if (phonesCount === 1) return;

        phoneDuplicates.push(l.phone);
      });

      if (phoneDuplicates.length) {
        phoneDuplicates = [...new Set(phoneDuplicates)];

        this.$message({
          message: `В добавляемом списке слушателей обнаружены записи с
            одинаковыми номерами телефона: ${phoneDuplicates.join(
              ", "
            )}. Удалите дубли и повторите попытку`,
          type: "error",
          duration: 15000,
        });

        return true;
      }

      return false;
    },
    async saveHandler() {
      if (
        this.hasIncorrectRecords() ||
        this.hasExistsListeners() ||
        this.hasEmptyRecords() ||
        this.hasDuplicatePhones()
      ) {
        return;
      }

      try {
        await this.$axios.post("/manager/listeners/validate", this.listeners);

        this.$emit("add-listeners", this.listeners);
      } catch (e) {
        const message = e.response.data.reduce((acc, curr) => {
          return (acc += `${curr.errorMessage}; `);
        }, "");

        this.$message({
          message,
          type: "error",
          duration: 10000,
        });
      }
    },
    clearData() {
      this.form = {
        name: "",
        email: "",
        phone: "",
      };
      this.content = "";

      setTimeout(() => {
        this.onPasteInEditor();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/manager/requests/add-listeners-modal.scss";
</style>