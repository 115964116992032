import Str from "@/helpers/Str";

export default {
  data: () => ({
    searchValue: "",
  }),
  computed: {
    filteredCoursesTree() {
      if (Str.empty(this.searchValue)) return this.coursesTree;

      return this.coursesTree.reduce((newTree, direction) => {
        const dName = direction.name.toLowerCase();
        const svalue = this.searchValue.toLowerCase();
        const isDirectionSuitable = dName.includes(svalue);

        // Если нет категорий, но направление подходит под фильтр
        if (!direction.childs || !direction.childs.length) {
          return isDirectionSuitable ? [...newTree, direction] : newTree;
        }

        const suitableCategories = this.suitableCategories(direction.childs);

        // Если категории не подходят под фильтр, но направление подходит
        if (!suitableCategories.length) {
          return isDirectionSuitable ? [...newTree, direction] : newTree;
        }

        const newDirection = { ...direction };

        newDirection.childs = suitableCategories;

        this.openCollapse(direction.id);

        return [...newTree, newDirection];
      }, []);
    },
  },
  methods: {
    openCollapse(id) {
      if (!this.$refs[id] || !this.$refs[id].length) return;
      this.$refs[id][0].openBody();
    },
  },
}