export default {
  methods: {
    async isValid(form = "form") {
      try {
        await this.$refs[form].clearValidate();
        await this.$refs[form].validate();
      } catch (e) {
        return false;
      }

      return true;
    },
  },
}